.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Base styles for the header */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: #fff;
}

.App-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

/* Styles for the ProfileBadge component */
/* Assuming ProfileBadge renders an img or an element with a class "profile-badge" */
.profile-badge {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  /* Add more styles based on your ProfileBadge component structure */
}

/* Responsive styles */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    padding: 0.5rem;
  }

  .App-header h2 {
    margin-bottom: 0.5rem;
  }

  /* Adjust profile badge size on smaller screens, if necessary */
  .profile-badge {
    width: 40px;
    height: 40px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
